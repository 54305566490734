<template>
  <div class="row justify-content-between align-items-md-center">
    <div :class="roles != 1 ? 'col-md-4' : 'col-md-6'">
      <v-card elevation="10">
        <div class="card-header">
          <h3 class="mb-0">Pasta</h3>
        </div>
        <div class="card-body">
          Rota: <span style="font-weight: 700"> {{ rotas.rota }}</span> <br />
          Pasta Escolhida:<span style="font-weight: 700">
            <b-icon icon="folder" class="ml-1" aria-hidden="true"></b-icon>
            {{ pasta.nome }}</span
          >
        </div>
      </v-card>
    </div>
    <div :class="roles != 1 ? 'col-md-4' : 'col-md-6'">
      <v-card elevation="10">
        <div class="card-header">
          <h3 class="mb-0">Permissões</h3>
        </div>
        <div class="card-body">
          <treeselect
            :options="lista_regras"
            placeholder="Selecione  as regras..."
            v-model="roles"
          />
        </div>
      </v-card>
    </div>
    <div v-if="roles != 1" class="col-md-4">
      <v-card elevation="10">
        <div class="card-header">
          <h3 class="mb-0">Grupo e Usuários</h3>
        </div>
        <div class="card-body">
          <div>
            <label for="Grupo">Grupo</label>
            <treeselect
              :multiple="true"
              :options="lista_grupo"
              placeholder="Selecione  Grupo..."
              v-model="form.grupo_id"
            />
          </div>
          <div>
            <label for="Usuarios">Usuarios</label>
            <treeselect
              :multiple="true"
              :options="lista_funcionarios"
              placeholder="Selecione  usuario..."
              v-model="form.usuarios_id"
            />
          </div>
        </div>
        <div class="d-flex justify-end">
          <v-btn class="mb-1 mr-2" @click="confirm('cria')" color="primary"
            >salvar</v-btn
          >
        </div>
      </v-card>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import { fireAlert } from "@/core/mixins/alertMessageMixin";

export default {
  mixins: [fireAlert],
  data() {
    return {
      form: {
        usuarios_id: [],
        grupo_id: [],
      },
      formValidat: {
        nome: {
          isValid: false,
          alreadyChanged: false,
        },
      },
      rotas: JSON.parse(window.localStorage.getItem("routers")),
      verif: false,
      roles: 1,
      usuarios_id: null,
    };
  },
  created() {
    this.listar_requerimentos();
    this.preenxerCampos();
  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Permissões Pastas" }]);
  },
  computed: {
    lista_funcionarios() {
      return this.$store.state.configUsuarios.lista_usuarios.map((u) => ({
        id: u.id,
        label: u.nome,
      }));
    },
    lista_filials() {
      return this.$store.state.configEmpresa.lista_filials;
    },
    lista_regras() {
      return [
        { id: 1, label: "Todos que tiverem permisão de pastas" },
        { id: 2, label: "Não visualizar" },
        { id: 3, label: "Visualização Apenas" },
        { id: 4, label: "Visualização e Edição Apenas" },
        { id: 5, label: "Visualização,impressao e Edição Apenas" },
      ];
    },

    lista_grupo() {
      return this.$store.state.grupo.lista_grupo.map((g) => ({
        id: `g_${g.id}`,
        label: g.nome,
        children: g.membros.map((u) => ({
          id: `u_${u.id}`,
          label: u.nome,
        })),
      }));
    },
    lista_campos() {
      return this.$store.state.configEmpresa.lista_campos;
    },

    pasta() {
      return this.$store.state.pastas.pasta;
    },
    lista_permissoes_pasta() {
      return this.$store.state.permissoesPasta.lista_permissoes_pasta;
    },
    mensagem_alert() {
      return this.$store.state.permissoesPasta.mensagem_alert;
    },
  },
  watch: {
    roles: function (permissao) {
      if (permissao != 1) this.popularPermissao(permissao);
    },
  },
  methods: {
    async confirm(tipo) {
      await this.$confirm({
        title: "Estas seguro?",
        message: `Esta ação  ` + tipo + ` uma Pasta no sistema?`,
        button: {
          no: "No",
          yes: "Si",
        },
        callback: (confirm) => {
          if (confirm) {
            if (tipo == "cria") this.create();
          }
        },
      });
    },
    async create() {
      if (this.$route.params.pastas_id) {
        await this.processarSelecoes();

        let usuarios = this.usuarios_id;
        let form = {
          usuarios_id: usuarios,
          permissao: this.roles,
          pasta_id: this.$route.params.pastas_id,
        };

        // console.log(form);

        this.verif = true;
        await this.$store.dispatch(
          "permissoesPasta/create_permissao_pasta",
          form
        );
        this.verif = false;
        this.fireAlert({
          ...this.mensagem_alert,
        });
      } else {
        this.fireAlert("entre novamente na pasta e acesse o menu permissão");
      }
    },
    processarSelecoes() {
      this.usuarios_id = [];

      this.form.grupo_id.forEach((id) => {
        console.log(id);
        // Verifica se o ID atual está em lista_grupo (indicando que é um grupo)
        const grupo = this.lista_grupo.find((grupo) => grupo.id === id);

        if (grupo) {
          // Se for um grupo, extrai os IDs de usuários desse grupo
          grupo.children.forEach((usuario) => {
            if (!this.usuarios_id.includes(usuario.id)) {
              this.usuarios_id.push(parseInt(usuario.id.replace("u_", "")));
            }
          });
        } else {
          // Se o ID não corresponder a um grupo, é tratado como um ID de usuário
          // Adiciona diretamente, assumindo que é um usuario_id
          // Aqui você precisa garantir que esse ID não é de um grupo, o que pode requerer uma lógica extra caso haja sobreposição
          if (!this.usuarios_id.includes(id)) {
            this.usuarios_id.push(parseInt(id.replace("u_", "")));
          }
        }
      });

      this.form.usuarios_id.forEach((id) => {
        if (!this.usuarios_id.includes(id)) {
          this.usuarios_id.push(id);
        }
      });
    },

    async popularPermissao(permissao) {
      this.form.grupo_id = [];
      await this.$store.dispatch("permissoesPasta/listar_permissoes_pasta", {
        pasta_id: this.$route.params.pastas_id,
        permissao: permissao,
      });

      if (this.lista_permissoes_pasta) {
        this.lista_grupo.forEach((grupo) => {
          // Mapeia os IDs dos membros para facilitar a comparação
          const membrosGrupoIds = grupo.children.map((membro) => membro.id);

          // Filtra as permissões para encontrar usuários com a permissão desejada
          const usuariosComPermissao = this.lista_permissoes_pasta
            .filter((permissao_pasta) =>
              membrosGrupoIds.includes(`u_${permissao_pasta.usuario_id}`)
            )
            .map((item) => `u_${item.usuario_id}`);

          // Verifica se o número de usuários com permissão corresponde ao número de membros no grupo
          if (usuariosComPermissao.length === grupo.children.length) {
            // Se todos os membros do grupo têm a permissão, adiciona o ID do grupo
            this.form.grupo_id.push(grupo.id);
          } else {
            // Caso contrário, adiciona os usuários individualmente
            usuariosComPermissao.forEach((usuarioId) => {
              if (!this.form.grupo_id.includes(usuarioId)) {
                this.form.grupo_id.push(usuarioId);
              }
            });
          }
        });
      }
    },

    formIsValid() {
      return Object.values(this.formValidat).every((field) => !!field.isValid);
    },

    listar_requerimentos() {
      // this.$store.dispatch("configEmpresa/listar_filial");
      this.$store.dispatch("pastas/listar_pasta", this.$route.params.pastas_id);
    },
    async preenxerCampos() {
      this.$store.dispatch("configUsuarios/listar_usuarios");
      await this.$store.dispatch("grupo/listar_grupos");
    },
  },
};
</script>

<style scoped>
.lista:hover {
  background-color: rgb(167, 183, 255);
}
</style>